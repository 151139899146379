import moment from "moment";
import eventsDB from "../database/eventsDB";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {
    getAnimalUnit,
    getManageSubgroups,
    getMaxDelayForBirth,
    getTimeFromInseminationToPartuition,
} from "./SettingsUtils";
import {getCorrectValidatedEventsData} from "./AnimalDocumentsUtils";
import * as AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import {getPigBalance} from "./EventUtils";
import {CycleActions} from "../constans/cycleActions";
import {first, get, isArray, isNil, isString, last, minBy} from "lodash";
import groupsDB from "../database/groupsDB";
import store from "../store/store";
import {makeGetManageBuildingsList} from "../selectors/buildingsSelector";
import i18n from "../i18n";
import memoizeOne from "memoize-one";
import {convertWeightUnitTo} from "./UnitUtils";
import {AnimalWeightOptions} from "../constans/weightOptions";
import {ValidationDefaultValues} from "../views/new-settings-view/validations/validation-values/ValidationDefaultValues";
import EstimatedWeight from "../beans/estimated-weight/EstimatedWeight";
import {myID} from "../libs/generateID";
import animalsDB from "../database/animalsDB";

export function addZerosToRfid(RFID) {
    try {
        for (let i = RFID.length; i < 15; i++) {
            RFID = "0" + RFID;
        }
        return RFID;
    } catch (err) {
        console.error(err);
    }
}

export function checkIfSowHadEventInPregnancy(
    AnmID,
    startTime,
    type = EventTypes.INSEMINATION
) {
    let events = eventsDB
        .getAllEvents4AnimalWithType(AnmID, type)
        .filter(
            (item) =>
                item.EvTime <= startTime &&
                moment(startTime)
                    .startOf("day")
                    .diff(moment(item.EvTime).startOf("day"), "days") <=
                    getTimeFromInseminationToPartuition() +
                        getMaxDelayForBirth()
        ) // sprawdza w przedziale od daty nowego zdarzenia do czasu z ustawien czas do porodu + maks opoznienie (np. 112 + 5)
        .sort((a, b) => b.EvTime - a.EvTime);
    return events[0];
}

export function getAbnormalitiesForAnimal(animal, events, cycles) {
    let data = [];
    let cyclesWithErrors = getCorrectValidatedEventsData(
        cycles.cycleTable,
        animal
    );
    for (let cycle of cyclesWithErrors) {
        data.push(...cycle.invalidEvents);
    }
    // wyliczenie ujemnej ilości prosiąt na każdej maciorze
    const lastCycle = last(cycles.cycleTable);
    let eventsInCycle = [];
    for (let key in lastCycle) {
        if (lastCycle.hasOwnProperty(key) && Array.isArray(lastCycle[key])) {
            eventsInCycle.push(...lastCycle[key]);
        }
    }
    if (animal.AnimalKind === AnimalTypes.SOW) {
        const pigBalance = getPigBalance(eventsInCycle);
        if (pigBalance < 0) {
            data.push({
                Reason: CycleActions.HAS_NEGATIVE_PIGLETS_AMOUNT,
                AdditionalData: {balance: pigBalance},
            });
        }
    }
    return data;
}

export const checkIfAnimalIsInPlcmntID = (animal, PlcmntID) => {
    if (!animal) return false;
    if (isString(animal.PlcmntID) && PlcmntID === animal.PlcmntID) return true;
    if (!isArray(animal.PlcmntID)) return false;
    return !!animal.PlcmntID.find((o) => o.PlcmntID === PlcmntID);
};

export const checkIfTreatAnimalAsIndividual = (animal) => {
    return [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(animal?.AnimalKind)
        ? !!animal?.RFID
        : true;
};

export function getAnimalLocationInTime(animal, events, time) {
    const transfers = events.filter(
        (item) =>
            item.EvCode === EventTypes.TRANSFER &&
            !item.DtaDelTime &&
            item.EvTime < time
    );
    transfers.sort((a, b) => b.EvTime - a.EvTime);
    return transfers[0]?.EvData.DstID || animal.PlcmntID;
}

const _getBuildingList = makeGetManageBuildingsList();

/**
 * formatter used to show pretty animal name
 *
 * if optional param is left null value will be set automatically
 * but whole function could take slower to complete due to more steps needed
 * use with caution with big animal arrays
 * @param animal {object} - animal object
 * @param groups {null|array} - list of all groups for the farm
 * @param manageSubgroups {null|boolean} - flag showing if managing animal groups is enabled
 * @param buildingList {null|array} - list of buildings .id and .name is needed
 * @param showLocation
 * @param showCount
 * @param showOnlySubgroup
 * @returns {string}
 */
export const formatAnimalName = (
    animal,
    {
        groups = null,
        manageSubgroups = null,
        buildingList = null,
        showLocation = true,
        showCount = false,
        showOnlySubgroup = false,
    } = {}
) => {
    let name = animal.AnmNo1 || " - ";
    // set value if was not passed
    if (manageSubgroups === null) {
        manageSubgroups = getManageSubgroups();
    }
    // name change is only done when the manage subgroups options is disabled and animal kind is a porker or a piglet
    if (
        !manageSubgroups &&
        [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(animal.AnimalKind)
    ) {
        // get groups if was not passed
        const state = store.getState();
        if (groups === null) {
            groups = groupsDB.getAllGroups(state.location.farm, {
                showDeleted: true,
            });
        }
        // get building list if was not passed
        if (showLocation && buildingList === null) {
            buildingList = _getBuildingList(state, {showDeleted: true});
        }
        // sort groups that newer groups are first and deleted groups are last
        groups.sort((o1, o2) => {
            return (
                +!!o1.DtaDltTime - +!!o2.DtaDltTime ||
                o2.DtaCrtTime - o1.DtaCrtTime
            );
        });
        //
        const group =
            groups.find(({AnmIDs}) => AnmIDs.includes(animal.AnmID)) ||
            groups.find(({Rmvd}) => Rmvd.includes(animal.AnmID));
        if (group) {
            name = group.GrNo1;
            // if animal has RFID it must have unique AnmNo1 number
            if (animal.RFID || animal.Tagged) {
                name = showOnlySubgroup
                    ? animal.AnmNo1
                    : name + ` - ${animal.AnmNo1}`;
            } else {
                const location = showLocation
                    ? buildingList.find(({id}) => id === animal.PlcmntID)
                    : null;

                if (showLocation && location) {
                    name += ` - ${location.name}`;
                }
                if (showCount && animal.AnmCnt > 1) {
                    name += ` (${i18n.t("pcs", {count: animal.AnmCnt})})`;
                }
            }
        } else {
            console.log(
                "[could not find group for animal %s %s]",
                animal.AnmNo1,
                animal.AnmID
            );
        }
    }
    // fallback if AnmNo1 is same as AnmID use animalKind instead
    if (name === animal.AnmID) {
        name = `${i18n.t(`animalKind.${animal.AnimalKind}`)} (${name})`;
    }
    return name;
};

/**
 *
 * @returns {array}
 */
export const getAnimalKinds = memoizeOne((t = i18n.t) => {
    return Object.values(AnimalTypes).map((animalType) =>
        t(`animalKind.${animalType}`)
    );
});

export const weightFormatter = (value) => {
    const unit = getAnimalUnit();
    return convertWeightUnitTo(value, {
        showUnit: true,
        unit: unit,
        fixed: unit ? 2 : 0,
    });
};

export const getBaseForWeight = (piglets, {animalWeightOption}) => {
    return !+piglets || animalWeightOption === AnimalWeightOptions.SINGLE
        ? 1
        : +piglets;
};

export const getSaleWeightRange = (formikBag) => {
    const {
        props: {validations, eventChooserFilter},
    } = formikBag;
    let validationKey = "averagePigletWeightForSale",
        defaultValidationValue =
            ValidationDefaultValues.averagePigletWeightForSale;
    if (eventChooserFilter === AnimalTypes.PORKER) {
        validationKey = "averagePorkerWeightForSale";
        defaultValidationValue =
            ValidationDefaultValues.averagePorkerWeightForSale;
    }
    return get(validations, validationKey, defaultValidationValue);
};

export const getArithmeticWeight = (_arr) =>
    _arr.reduce((w1, w2) => w1 + w2) / _arr.length;

export const getBirthDateFromAnimalGroup = (animals) => {
    return minBy(animals, (animal) => animal.DtaBrthTime)?.DtaBrthTime || null;
};

export const getEstimatedWeightByAnimalKind = (
    row,
    date = moment(),
    convert = true
) => {
    const {GroupBirth, DtaBrthTime, AnimalKind} = row;
    const estimatedWeight = new EstimatedWeight(
        GroupBirth || DtaBrthTime,
        [],
        AnimalKind
    ).getWeightByDate(date);
    if (convert)
        return convertWeightUnitTo(estimatedWeight, {
            unit: getAnimalUnit(),
            rawValue: true,
        });
    return estimatedWeight;
};

export function createTemporaryAnimalObject(
    AnmNo1,
    AnimalKind,
    AnmCnt,
    FarmID,
    PlcmntID,
    additionalInfo = {}
) {
    return {
        AnmID: myID(),
        AnmNo1,
        AnimalKind,
        AnmCnt,
        FarmID,
        PlcmntID,
        DtaModTime: +new Date(),
        DtaInTime: +new Date(),
        ...additionalInfo,
    };
}

export function isAnimalRemoved(animal) {
    return !!animal.DtaDelTime;
}

export function prepareAnimalObjectToSave(animal) {
    const clone = JSON.parse(JSON.stringify(animal));
    delete clone.events;
    delete clone.meta;
    delete clone.Cycles;
    delete clone.LastSeparation;
    return clone;
}

export function animalHasPlcmntID(animal, _PlcmntID) {
    if (!animal) return false;
    const placementList = isString(_PlcmntID)
        ? [_PlcmntID]
        : isArray(_PlcmntID)
          ? _PlcmntID
          : [];
    if (isString(animal.PlcmntID)) {
        return placementList.includes(animal.PlcmntID);
    } else if (isArray(animal.PlcmntID)) {
        for (let {PlcmntID} of animal.PlcmntID) {
            if (placementList.includes(PlcmntID)) return true;
        }
    }
    return false;
}

export function isGiltForbidden(gilt) {
    const forbiddenKeys = [
        "DtaDthTime",
        "DtaDelTime",
        "SeparationTime",
        "ReclassifyTime",
        "MommyTime",
    ];
    return forbiddenKeys.some((key) => Object.keys(gilt).includes(key));
}

export function getPigletGilts(events) {
    let piglets_sow = [];
    for (const event of events) {
        const {EvData, EvCode, AnmID} = event;
        const animal = animalsDB.getAnimalById(AnmID);
        if (Array.isArray(EvData?.TattooedAnimals)) {
            for (let item of EvData?.TattooedAnimals) {
                if (isGiltForbidden(item)) continue;
                piglets_sow.push({
                    AnmNo1: item.AnmNo1,
                    DtaBrthTime: EvData?.DtaBrthTime,
                    PlcmntID: animal?.PlcmntID,
                    MomID: AnmID,
                });
            }
        } else if (
            EvCode === EventTypes.SEPARATION_TO_MOMMY_GILTS &&
            !isGiltForbidden(EvData)
        ) {
            piglets_sow.push({
                AnmNo1: EvData?.AnmNo1,
                DtaBrthTime: EvData?.DtaBrthTime,
                PlcmntID: animal?.PlcmntID,
                MomID: AnmID,
            });
        }
    }
    return piglets_sow;
}

export function isIndividualAnimal(animal) {
    return (
        animal.AnmCnt === 1 &&
        (animal.RFID ||
            animal.Tagged ||
            ![AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(
                animal.AnimalKind
            ))
    );
}

export const isAnimalRemovedOrSoldOrFallen = (animal) => {
    if (!isNil(animal?.DtaDthTime)) return true;
    return !isNil(animal?.DtaDelTime);
};

export const SowStatuses = {
    REMOVED: "REMOVED",
    ALIVE: "ALIVE",
    DEAD: "DEAD",
    SOLD: "SOLD",
    IDLE: "IDLE",
    FARROWED: "FARROWED",
    INSEMINATED: "INSEMINATED",
    NOT_IN_PRODUCTION: "NOT_IN_PRODUCTION",
    SEPARATED: "SEPARATED",
};

export const getSowStatus = (animal, cycleTable) => {
    if (animal.DtaDthTime) {
        switch (animal.DthRsn) {
            case 1:
                return SowStatuses.SOLD;
            default:
                return SowStatuses.DEAD;
        }
    } else if (animal.DtaDelTime) return SowStatuses.REMOVED;
    else {
        const lastCycle = last(cycleTable);
        if (!lastCycle) return SowStatuses.NOT_IN_PRODUCTION;
        const separation = last(lastCycle[EventTypes.SEPARATION]);
        if (separation) {
            const diff = moment().diff(separation.EvTime, "days");
            if (diff > 5) {
                return SowStatuses.IDLE;
            } else {
                return SowStatuses.SEPARATED;
            }
        }
        const parturition = first(lastCycle[EventTypes.PARTURITION]);
        if (parturition) {
            return SowStatuses.FARROWED;
        }

        const insemination = first(lastCycle[EventTypes.INSEMINATION]);
        if (insemination) {
            return SowStatuses.INSEMINATED;
        }
        return SowStatuses.NOT_IN_PRODUCTION;
    }
};

export function getAnimalState(animal, date) {
    const {balance} = eventsDB.getPigBalanceInSpecificCycle(animal, date, true);
    const activeNipples = eventsDB.getActiveNipplesInCurrentCycle(animal);
    return {piglets: Math.max(balance, 0), activeNipples};
}
